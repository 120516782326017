import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/home/Hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default","AnimatedSection"] */ "/vercel/path0/components/shared/AnimatedContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/shared/cards/InfoCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Accordion","AccordionItem","AccordionTrigger","AccordionContent"] */ "/vercel/path0/components/ui/accordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/bouquet.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/crystal.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/arrang.jpeg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/newcrystalll.jpeg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/treasure.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/Leatherbag.jpg");
