
import React from 'react'
import Image from 'next/image'

import svgFile from '@/public/logo.png'
const Logo = () => {
    return (
      //   <svg
      //   xmlns="http://www.w3.org/2000/svg"
      //   viewBox="0 0 245.75 171.84"
      //   width="120" height="70"
      // >
      //   <defs>
      //     <style>{".cls-1{fill:#cb9492;}"}</style>
      //   </defs>
      //   <g id="Layer_2" data-name="Layer 2">
      //     <g id="Layer_1-2" data-name="Layer 1">
      //       <path
      //         className="cls-1"
      //         d="M171,44.45c.17-.13,1.05.29,1,.37a14.66,14.66,0,0,0-2.59,5.38c.43,1.31.82,2.62,1.2,3.93a4.91,4.91,0,0,0,.51-.15,12.55,12.55,0,0,0,3-1.59,32.66,32.66,0,0,0,6.73-6,4.67,4.67,0,0,1-.5-3.18,7.62,7.62,0,0,1,2.17-4.14c.63-.58,3.56-2.38,4.14-1a1.47,1.47,0,0,1-.08,1.05,19.21,19.21,0,0,1-3.09,5.1c-.21.25-1.76,2.3-1.76,2.3a8.72,8.72,0,0,0,3.6,2.09,13.25,13.25,0,0,0,2.5.25,20.21,20.21,0,0,0,8.86-2.26C185.2,18.6,159.91,0,116.67,0H53.27s2.18,6.43,11.88,6.43h48.12c31.24,0,47.32,19.38,55.17,41.11A8.71,8.71,0,0,1,171,44.45"
      //       />
      //       <path
      //         className="cls-1"
      //         d="M183.12,48.29A5.1,5.1,0,0,1,181.28,47c-.67.71-1.38,1.38-2.09,2.09-2.29,2.17-4.93,5.06-8.23,5.69h-.2a129.48,129.48,0,0,1,4.45,33.7c0,42.86-14.45,78.39-67.15,78.39H97C74.15,166.91,74.54,97,73.6,80.08,73.37,75.9,65.72,69,65.72,69s.6,87.33-14.77,97.11c-2,1.29-4.66,1.9-7.83,2.26a3.36,3.36,0,0,0-2.83,3.45h67.08c57.24,0,96.74-38.23,96.74-83.32,0-15.06-2.3-29.09-7.22-41.36-4.36,2-9.26,3-13.77,1.13"
      //       />
      //       <path
      //         className="cls-1"
      //         d="M184,41.86c.54-1,1.84-2.47,1.63-3.64a3.74,3.74,0,0,0-2.13,1.21A7.86,7.86,0,0,0,181.57,43a6.43,6.43,0,0,0-.16,2.67c0-.29,1.17-1.59,1.38-1.88.42-.63.84-1.25,1.21-1.92"
      //       />
      //       <path
      //         className="cls-1"
      //         d="M33.2,47.88a1.83,1.83,0,0,0-.42-.25,28.71,28.71,0,0,0-3-1.17,28,28,0,0,0,5.56-9.24,13.17,13.17,0,0,0,.75-7.82c-1-4.06-5.52-2.68-8.06-1.51a34,34,0,0,0-7.16,4.56c-1.12.88-2.21,1.84-3.3,2.76a13.2,13.2,0,0,0-2.67,2.34A40,40,0,0,0,17.82,33c.12-.25.24-.5.37-.8,0,0,.08-.12.13-.17,1.08-2.29,1.63-5.31-1.22-6.14s-5.56,1.13-7.23,3.34c0,0,.54.38.67.25a9.56,9.56,0,0,1,3.76-2.71c.8-.3,2.76-.88,3.43.08a2.77,2.77,0,0,1-.12,2.3c-1,2.93-3.31,5.64-5.1,8-2.1,2.68-4.23,5.31-6.23,8S1.34,51,0,54.23c-.13.34,1.5,1,1.75.75a21.26,21.26,0,0,0,3.09-4c.26-.29.47-.62.72-.92a2.92,2.92,0,0,0,.71,2.55c1.21,1.47,3.72,1.93,5.39,2.13,5.35.72,10.41-1.79,14.67-5.1A25,25,0,0,0,29.27,47c1.66.55,3.22,1.27,4.82,1.93a4.59,4.59,0,0,1,.17-.48,11.64,11.64,0,0,0-1.06-.53M22.67,49.67a20.59,20.59,0,0,1-9.24,4.27c-2.34.33-7.36,0-6.65-3.76A5.38,5.38,0,0,1,9.2,46.91c2.8-1.33,5.9-1.5,9.07-1.5a39.07,39.07,0,0,1,8.32.67,28.72,28.72,0,0,1-3.92,3.59m4.22-4a37.14,37.14,0,0,0-7.15-.83c-3.47-.09-7.07-.21-10.24,1H9.37a2.74,2.74,0,0,1-.42.25c-.29.13-.58.26-.87.42a60.63,60.63,0,0,1,7.39-7.94A91.91,91.91,0,0,1,24,31.41c2.84-2.05,5.77-4.27,9.24-4.18,1.21,0,1.88,1.38,1.63,3a20.68,20.68,0,0,1-1.42,4.59A43.71,43.71,0,0,1,26.89,45.7"
      //       />
      //       <path
      //         className="cls-1"
      //         d="M43.65,38.26c.3.09.84-.5,1-.71A1.08,1.08,0,0,0,44.53,36a.69.69,0,0,0-.83-.13c-.42.17-.76.71-1.05,1-.5.55.63,1.3,1,1.42"
      //       />
      //       <path
      //         className="cls-1"
      //         d="M54.4,47.61a8,8,0,0,1,.74-1.8,84,84,0,0,1-8.56,6.08c-2.17,1.13-5.52,2.84-8,2.59a3.25,3.25,0,0,1-2.67-1.42c-.54-1.17-.09-2.8.33-3.89a24.55,24.55,0,0,1,3.26-5.35c.76-1,1.55-2.09,2.35-3.09A2.76,2.76,0,0,0,40,39.85,47.85,47.85,0,0,0,36.26,45c-.55.84-1,1.68-1.55,2.51a9.12,9.12,0,0,0-.45.95,8.13,8.13,0,0,1,1.28.85c.21.17-.42,0-.46,0-.34-.13-.66-.28-1-.41-.78,2.26-.46,4.54,2.17,5.68s5.68.12,8.15-.8,6.72-3.73,10-6.16"
      //       />
      //       <path
      //         className="cls-1"
      //         d="M35.54,49.26a8.47,8.47,0,0,0-1.29-.85c-.06.16-.11.32-.16.48.33.13.65.28,1,.41,0,0,.67.12.46,0"
      //       />
      //       <path
      //         className="cls-1"
      //         d="M57.79,45c-.84.71-2,1.66-3.39,2.66-.51,1.91-.08,3.74,2.46,4.24S61.8,51,63.8,49.21c-.62,2.22-.21,4.23,2.39,5.35s5.68.13,8.15-.79c3.55-1.29,9.91-6.39,12.79-8.82.13-.13-.83-.63-1-.46-2,1.67-7.28,6.19-9.58,7.4s-5.56,2.84-8,2.59c-1.34-.13-2.76-.83-2.89-2.17a4.94,4.94,0,0,1,.17-1.76,12.39,12.39,0,0,1,1.67-3.89c1.34-2.25,3.14-4.22,4.6-6.43.08-.17-1-.59-1.17-.46a30.88,30.88,0,0,0-3.55,3.55v-.67a2.15,2.15,0,0,0-1.84-2.46c-3.89-.76-7.9,1.88-10,5-.12.19-.23.4-.34.6.66-.51,1.24-1,1.64-1.31.21-.17,1.17.33,1,.46m3-2.59c1.13-.75,3.67-2,5-1.13s.42,3.26-.25,4.43a11.89,11.89,0,0,1-4.05,4.14,6.29,6.29,0,0,1-1.43.79,5.36,5.36,0,0,1-2.88.68c-2.09-.38-.88-3.35-.25-4.48a11.74,11.74,0,0,1,3.85-4.43"
      //       />
      //       <path
      //         className="cls-1"
      //         d="M57.78,45c.17-.12-.79-.63-1-.46-.4.34-1,.8-1.64,1.32a8,8,0,0,0-.74,1.8c1.35-1,2.55-2,3.38-2.66"
      //       />
      //       <path
      //         className="cls-1"
      //         d="M92.61,44.87c-1,1.25-1.92,2.51-2.84,3.8s-1.84,2.59-2.72,3.93c-.29.42-1,1.13-.83,1.72a1.19,1.19,0,0,0,1.54.62,3.25,3.25,0,0,0,1.38-1.25c3.09-4.23,6.15-8.45,9.24-12.67a1.9,1.9,0,0,1,.46-.54,10.88,10.88,0,0,1,5.56-.13c.33.09.75,0,.25-.29h0s-.08,0-.17-.09h0a2.4,2.4,0,0,0-.54-.2,9.39,9.39,0,0,0-4.18-.42c.83-1,1.25-1.55,1.58-2,.67-1,1.38-2.05,2-3.13a26.15,26.15,0,0,0,1.22-2.35,11.55,11.55,0,0,0,1-2.46c.21-.21.5-.17,1.25-.38a58.88,58.88,0,0,1,11-2.13c.54,0,1.17,0,1.38.55s-.21,1.12,0,1.66a.86.86,0,0,0,1.22.42,1.57,1.57,0,0,0,.16-2.34,2.07,2.07,0,0,0-.7-.54,4.46,4.46,0,0,0-1.64-.38,21.52,21.52,0,0,0-5.81.42,64.71,64.71,0,0,0-6.73,1.76c0-.09,0-.21,0-.3a2.4,2.4,0,0,0-1.71-2,1,1,0,0,0-.21-.12A4.75,4.75,0,0,0,100.6,26a8.61,8.61,0,0,0-4.06,2.38c-.63.67-1.21,1.72-.75,2.55s1.46.92,2.34.84a33.77,33.77,0,0,0,5-1.17v.08a53.89,53.89,0,0,1-3.26,5,18,18,0,0,1-3.55,4.18c-1,.71-2.25,1-3.26,1.63l-1,.5c-.09,0,.75.5,1,.42l2.09-.75c-.38.46-.84,1.17-1,1.29-.55.63-1,1.3-1.55,1.93M101.18,30a8.32,8.32,0,0,1-3.72.75c-2.72-.34,2.26-3.72,3-4s2.76-.87,3.43.09a3.08,3.08,0,0,1-.08,2.25c-.88.3-1.76.63-2.64.93"
      //       />
      //       <path
      //         className="cls-1"
      //         d="M134.46,44.87a1.78,1.78,0,0,1,.5.12c0-.06.08-.12.11-.19-.21-.19-.79-.44-.95-.31a97.53,97.53,0,0,1-10.19,7.4c-2.18,1.13-5.52,2.84-8,2.59a3.27,3.27,0,0,1-2.68-1.42c-.54-1.17-.08-2.8.34-3.89a24,24,0,0,1,3.26-5.35c.29-.42.59-.79.87-1.17,4.69-2.42,9-6.44,12.55-10.2.83-.88,3.59-3.43,2.63-4.72-1.46-1.88-4.47,1.21-5.56,2.09a73.61,73.61,0,0,0-6.1,5.72A60.85,60.85,0,0,0,113.6,45c-.54.83-1,1.67-1.55,2.51-1.46,2.67-1.63,5.73,1.55,7.11,2.59,1.08,5.68.12,8.15-.8a45,45,0,0,0,8.62-5.15,7.05,7.05,0,0,1,4.09-3.75m-2.59-16.31c-.25,1.17-1.63,2.43-2.47,3.27a51.74,51.74,0,0,1-4.64,4.34,57.32,57.32,0,0,1-4.85,3.64c1.21-1.46,2.43-2.89,3.76-4.27"
      //       />
      //       <path
      //         className="cls-1"
      //         d="M148.38,51.85A16.08,16.08,0,0,1,143,53a9.05,9.05,0,0,1-4.77-1,1.48,1.48,0,0,0-.37-.16c2.84-1.26,5.35-3.64,5.89-6.36.92-4.3-4.51-4.64-7-2.67a5.25,5.25,0,0,0-1.65,2.06c.07.06.1.12.06.15-1.11.95-2.85,2.3-4.76,3.67-.73,1.71-.34,3.45,2.38,3.9A8.76,8.76,0,0,0,137,52.1a7.49,7.49,0,0,0,1.21.54,16,16,0,0,0,11.45-.58,38.78,38.78,0,0,0,6.28-3.31c.87-.53,1.76-1,2.63-1.61a14.13,14.13,0,0,1,1-1.82,47.51,47.51,0,0,1-11.15,6.53m-14.25.08c-1.38,0-2.68-.71-2.26-2.17a7.17,7.17,0,0,1,2.8-3.85,7,7,0,0,0,0,3.22,4.2,4.2,0,0,0,1.38,2.34,5.44,5.44,0,0,1-1.92.46m2.59-.75a2,2,0,0,1-.71-1.09,6.89,6.89,0,0,1-.29-3.34,5.46,5.46,0,0,1,4-4.48c2.05-.41,3.13.42,2.88,2.43a5.39,5.39,0,0,1-1,2.34A10.37,10.37,0,0,1,137.14,51a1.19,1.19,0,0,1-.42.21"
      //       />
      //       <path
      //         className="cls-1"
      //         d="M135.07,44.8c0,.07-.08.13-.11.19a1.82,1.82,0,0,0-.5-.13,7.07,7.07,0,0,0-4.09,3.76c1.92-1.38,3.65-2.73,4.76-3.67,0,0,0-.09-.06-.15"
      //       />
      //       <path
      //         className="cls-1"
      //         d="M168.74,53.1a8.78,8.78,0,0,1,.34-1.84c.08-.36.19-.71.29-1.06-.29-.89-.61-1.78-.93-2.66-.1.19-.23.36-.33.55a14.45,14.45,0,0,1-1.58,1.25A26.07,26.07,0,0,1,164,50.85c-.75.33-2.76,1.42-3.51,1.21-1.89-.59.08-4.52.62-5.6A56.24,56.24,0,0,1,165,40.9c.09-.17-1.17-.76-1.38-.55a26.79,26.79,0,0,0-4.07,5l.85-.62c.17-.13.92.29.88.33a17.1,17.1,0,0,1-2.72,2.11c-1,2.26-1.16,4.41,1.22,5.25,2,.75,4.42-.54,6.14-1.59a11.8,11.8,0,0,0,1.46-1,5.33,5.33,0,0,0-.42,3.22c.48,1.52,2.41,2,3.82,1.81-.06-.24-.13-.46-.19-.69-.94.25-1.76.24-1.83-1"
      //       />
      //       <path
      //         className="cls-1"
      //         d="M200.51,44.37a34.87,34.87,0,0,1-3.85,2.21c.08.19.15.39.23.58.9-.41,1.78-.86,2.63-1.35.12-.2.24-.41.37-.61s.41-.56.62-.83"
      //       />
      //       <path
      //         className="cls-1"
      //         d="M160.38,44.7l-.85.62a14.13,14.13,0,0,0-1,1.82A17.56,17.56,0,0,0,161.26,45s-.71-.45-.88-.33"
      //       />
      //       <path
      //         className="cls-1"
      //         d="M226.46,44.51c-1.87,1.53-8.68,6.32-10.47,7.3a22.5,22.5,0,0,1-5.94,2.42,11.73,11.73,0,0,1-7.52-.79,4.21,4.21,0,0,1-2-4.35h0a12.61,12.61,0,0,0,8.15-2.26,8.31,8.31,0,0,0,2.8-3.26,2.48,2.48,0,0,0-1.67-3.39c-3.54-.68-7.19,1.44-9.37,4.19.15-.1.32-.19.47-.3s1,.3.83.34c-.73.48-1.5,1-2.29,1.41a6.38,6.38,0,0,0-.92,2.73c-.26,3.34,3.13,5.43,6,6.1a15.35,15.35,0,0,0,9.07-.88c2.72-1,7.8-4.27,11.31-6.85.06-.1.09-.2.15-.3.41-.73.9-1.41,1.36-2.11M201.6,46.12A12.59,12.59,0,0,1,203.28,44a8.31,8.31,0,0,1,1.88-1.55,10.69,10.69,0,0,1,2.76-1.3,2.61,2.61,0,0,1,2.59.34c.88,1,.17,2.34-.59,3.18a12.88,12.88,0,0,1-9.24,4.09,13,13,0,0,1,.92-2.59"
      //       />
      //       <path
      //         className="cls-1"
      //         d="M201,44.07l-.45.3c-.22.27-.44.55-.63.83s-.26.41-.37.62c.79-.46,1.56-.93,2.29-1.41.13,0-.63-.5-.84-.34"
      //       />
      //       <path
      //         className="cls-1"
      //         d="M241.49,46.41c-.84-1.63,0-3.59.12-5.43,0-.37,0-.88-.37-1.09a1.17,1.17,0,0,0-.92-.17c-2.34.3-4.6,1.93-6.61,3.1-1.13.67-2.25,1.38-3.39,2.13-.54.38-1.08.71-1.62,1.13-.38.25-1.3.67-1.51,1.09a43.68,43.68,0,0,1,4.51-6.44,3.26,3.26,0,0,0-1.21-.71.41.41,0,0,0-.33,0,.48.48,0,0,0-.38.25,32.13,32.13,0,0,0-3.31,4.28l.17-.14c.21-.17,1.17.33,1,.45-.67.57-1.62,1.3-2.69,2.1a35.79,35.79,0,0,0-1.66,3.34c-.2.5-.5.92-.29,1.46s.92.67,1.46.3a6.66,6.66,0,0,0,.76-1c1.92-3,5.05-5,8.19-6.9,1.09-.67,2.26-1.33,3.43-2a10.28,10.28,0,0,1,3.3-1.46v.08s0,0,0,.08a16.72,16.72,0,0,0-.58,2.59c-.59,3.14,2.38,4.81,5.06,5.27l1.08-.5c-1.67-.13-3.51-.29-4.26-1.76"
      //       />
      //       <path
      //         className="cls-1"
      //         d="M226.65,44.37l-.19.14c-.46.7-1,1.39-1.36,2.11,0,.1-.09.2-.15.3,1.09-.79,2-1.53,2.7-2.1.17-.12-.8-.62-1-.45"
      //       />
      //     </g>
      //   </g>
      // </svg>
      <Image
      src={svgFile}  // Adjust this path to match your logo's location
      alt="Company Logo"
      width={100}
      height={50}
      priority
    />
    )
}

export default Logo
