'use client'
import { useRef } from 'react'
import { motion } from 'framer-motion'
import { useInView } from '@/hooks/useInView'
import Link from 'next/link'
import Image from 'next/image'

import imageSrc from '@/public/images/bannermoh.jpg'
import { useRTLAwareStyle } from '@/util/rtl'

type Props = {
  title1: string;
  title2: string;
  lang: string;
  buttonText: string;
  buttonLink: string;
}

export default function Hero({
  title1, 
  title2, 
  lang, 
  buttonText, 
  buttonLink
}: Props) {
  const { ref, isInView } = useInView()
  const rtlAwareUnderlineStyle = useRTLAwareStyle('left-0', 'right-0');

  return (
    <motion.section
      ref={ref}
      initial={{ opacity: 0 }}
      animate={isInView ? { opacity: 1 } : {}}
      transition={{ duration: 0.8, ease: 'easeOut' }}
      className="w-full xl:container mx-auto relative flex flex-col items-center"
    >
      <div className="relative w-full h-[200px] md:h-[400px] lg:h-[550px] overflow-hidden">
        <Image
       priority
       src={imageSrc}
       alt= 'test'
       quality={85}
       fill
       sizes="(max-width: 768px) 100vw, 100vw"
       className="object-cover"
        />
      </div>

      {/* <Link 
          href={buttonLink}
          className="px-6 py-3 bg-primary text-white mt-4 font-roboto hover:bg-primary-dark transition-colors duration-300"
        >
          {buttonText}
        </Link> */}

        <Link
          href={buttonLink}
          className="inline-block relative group mt-4"
          prefetch={false}
        >
          <span className="text-gray-600 relative z-10 transition-opacity duration-300 ease-in-out group-hover:opacity-70 font-roboto">
          {buttonText}
          </span>
          <span className={`absolute bottom-0 ${rtlAwareUnderlineStyle} w-full h-[1px] bg-black transition-all duration-700 ease-in-out group-hover:w-0`}></span>
        </Link>

      {/* <div className="relative w-full h-[200px] md:h-[400px] lg:h-[500px]">
                <Image
                priority
                src={HeroImage}
                alt= 'test'
                quality={85}
                fill
                sizes="(max-width: 768px) 100vw, 100vw"
                className="object-cover"
                />
            </div> */}
      
      <motion.h1
        initial={{ opacity: 0, y: 20 }}
        animate={isInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.6, delay: 0.4, ease: 'easeOut' }}
        className={`my-4 md:my-10 text-xl md:text-2xl text-center flex font-roboto ${lang === 'en' && "test"}`}
      >
        {title1} {title2}
      </motion.h1>
      
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={isInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.6, delay: 0.6, ease: 'easeOut' }}
        className="mb-6"
      >
      </motion.div>
    </motion.section>
  )
}